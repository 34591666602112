import { useEffect, useState } from "react"
import { useMediaPredicate } from "react-media-hook"
import { useIsStart } from "@/lib/helpers/template"
import { useAppContext } from "@/lib/contexts/appContext"
import Image from "next/legacy/image"
import Blocks from "@/components/Blocks"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import Article from "@/components/common/article/article"
import Block from "@/components/wrappers/block"
import { ArchiveProvider } from "@/lib/contexts/archiveContext"
import SearchPanel from "@/common/search-panel/search-panel"
import useMenuItems from "@/lib/helpers/menu"
import styles from "./hero.module.css"
import classNames from "classnames"

export default function Hero({ block, settings }) {
  const [hasMounted, setMounted] = useState(false)
  const isMobile = useMediaPredicate("(max-width: 768px)")
  const hasTopMenu = !!useMenuItems("top-bramiljoval")

  // This is used due to isMobile not working before mount
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setMounted(true)
    }
    return () => {
      isMounted = false
    }
  }, [])

  const { innerBlocks } = block
  const {
    image,
    video,
    align,
    layout,
    size,
    opacity,
    mediaType,
    mediaAlign,
    mediaAuthor,
    panelSelect
  } = block.attrs

  let imageSrc = isMobile && image.tall ? image.tall : image.wide
  let videoSrc = isMobile && video.tall ? video.tall : video.wide
  let hasBackground = imageSrc || videoSrc
  let textColorClass = hasBackground ? "text-white" : "text-black-100"

  const baseBackground = mediaType === "video" ? "" : "bg-good-green-hero"
  const hasArticles = block.attrs.articles.length > 0
  const blockGap = hasArticles ? "normal" : block.attrs.blockGap

  const classes = classNames(
    styles.hero,
    layout,
    baseBackground,
    textColorClass,
    layout,
    size,
    {
      "has-articles": hasArticles,
      "no-panel": panelSelect === "hide",
      "search-panel": panelSelect === "search",
      "is-centered": align === "center",
      "has-top-menu": hasTopMenu
    }
  )

  return (
    <Block block={block} className={classes} width="bleed" gap={blockGap}>
      {hasMounted &&
        mediaType === "image" &&
        imageSrc &&
        layout !== "bra-miljoval" && (
          <figure className="background">
            <Image
              className="object-cover object-center w-full"
              src={imageSrc.url}
              alt={block?.attrs?.image?.wide?.alt}
              layout="fill"
              priority
            />
          </figure>
        )}
      {hasMounted && mediaType === "video" && (
        <div className={classNames(styles.background, "items-" + mediaAlign)}>
          <video
            className={styles.videoOverlay}
            preload="auto"
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}>
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
      )}
      {hasBackground && (
        <div className={classNames(styles.overlay, "opacity" + opacity)} />
      )}
      <Breadcrumbs
        hidden={useIsStart() || settings?.hideBreadcrumbs}
        wrapperClassName="hero-breadcrumbs"
      />
      <div className="inner-wrapper">
        <div className="content">
          <Blocks blocks={innerBlocks} settings={settings} />
          <MediaAuthor mediaAuthor={mediaAuthor} textAlign={align} />
        </div>
        {hasMounted && block.attrs.image.url && layout === "bra-miljoval" && (
          <figure className="hero-image">
            <Image
              className="object-cover object-center w-full"
              src={block.attrs.image.url}
              alt={block?.attrs?.image?.alt}
              layout="fill"
              priority
            />
          </figure>
        )}
      </div>
      <Articles attrs={block.attrs} textColorClass={textColorClass} />
      {layout === "bra-miljoval" && panelSelect === "search" && (
        <Search search={block.attrs?.search} textColorClass={textColorClass} />
      )}
    </Block>
  )
}

export function MediaAuthor({ mediaAuthor, textAlign }) {
  if (!mediaAuthor) {
    return null
  }

  return (
    <>
      <div className={classNames(styles.mediaAuthor, "text-" + textAlign)}>
        Foto: {mediaAuthor}
      </div>
    </>
  )
}

const Articles = ({ attrs, textColorClass }) => {
  const { articles, articlesHeading } = attrs

  if (attrs.layout === "bra-miljoval" && attrs.panelSelect !== "articles") {
    return
  }

  if (articles.length === 0) {
    return null
  }

  return (
    <div className="panel-wrapper">
      <div className="articles-wrapper">
        {articlesHeading && (
          <h3 className={classNames([textColorClass])}>{articlesHeading}</h3>
        )}
        <div className={styles.articles}>
          {articles.map((article, index) => (
            <Article
              key={`article-${index}`}
              className={styles.article}
              item={article}
              showTag={false}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const Search = ({ search }) => {
  const appContext = useAppContext()
  return (
    <div className="panel-wrapper">
      <div className="search-wrapper">
        <h3>{search?.heading}</h3>
        <p>{search?.text}</p>
        <ArchiveProvider
          isPanel={true}
          context={search.context}
          object={search}
          phrase={appContext.phrase}
          selected={appContext.selected}>
          <SearchPanel />
        </ArchiveProvider>
      </div>
    </div>
  )
}
